import * as React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import styles from './p404.module.css';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import { meta } from 'metaData';

function P404() {
  return (
    <Layout metaData={{ main: meta.notFound }} isRed>
      <main className={clsx('inner', styles.main)}>
        <section className={styles.content}>
          <h1 className={styles.title}>404</h1>
          <p className={styles.subtitle}>Oops ... we can't find this page</p>
          <p className={styles.text}>
            Maybe you are interested in our blog or want to view our latest work. Of course you can
            always write us on{' '}
            <a href="mailto:hello@brocoders.team" className={styles.link}>
              hello@brocoders.team
            </a>
          </p>
          <Link className={styles.back} to="/">
            Back to home
          </Link>
        </section>
      </main>
      <Footer />
    </Layout>
  );
}

export default P404;
